import React, { useEffect } from 'react';

/** Import CSS */
import '../assets/styles/home.scss';

/** Import Common Component */
import MenuComponent from '../components/common/MenuComponent';
import FooterComponent from '../components/common/FooterComponent';

/** Import Component */
import BannerComponent from '../components/home/BannerComponent';
import TopProductComponent from '../components/home/TopProductComponent';
import CategoryComponent from '../components/home/CategoryComponent';

function Home() {
    /** Redirect to top on page load */
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div className='main-body-section'>
            <MenuComponent></MenuComponent>

            <div className='first-main-section'>
                <div className='home-sections'>
                    <div className='home-page-section'>
                        <BannerComponent></BannerComponent>
                    </div>
                    
                    <div className='wcm-container-home home-page-section'>
                        <TopProductComponent></TopProductComponent>
                    </div>

                    <div className='wcm-container-home home-page-section'>
                        <CategoryComponent></CategoryComponent>
                    </div>
                </div>
            </div>

            <FooterComponent></FooterComponent>
        </div>
    )
}

export default Home;