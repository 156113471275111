import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

/** Import Utils */
import Pagination from "../../utils/Pagination";
import EnquiryModalSale from "../../utils/EnquiryModalSale";

/** Import Services */
import productsService from "../../services/productsSale";
import ageGroupService from "../../services/ageGroups";

const ProductListComponent = ({ filters }) => {
    const [products, setProducts] = useState([]);
    const [filteredProducts, setFilteredProducts] = useState([]);
    const [ageGroups, setAgeGroups] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [showModal, setShowModal] = useState(false);
    const [selectedProduct, setSelectedProduct] = useState(null);
    const productsPerPage = 16;

    useEffect(() => {
        setProducts(productsService);
        setAgeGroups(ageGroupService);
    }, []);

    useEffect(() => {
        // Apply filters
        let filtered = products.filter(product => {
            const matchCategories = filters.categories.length > 0 ? filters.categories.includes(product.categoryId) : true;
            const matchAgeGroups = filters.ageGroups.length > 0 ? filters.ageGroups.includes(product.ageGroupId) : true;
            const matchGenders = filters.genders.length > 0 ? filters.genders.includes(product.genderId) : true;
            const matchPrice = product.prices.discountedSalePrice >= filters.price.min && product.prices.discountedSalePrice <= filters.price.max;
            const matchSearch = filters.search ? product.productName.toLowerCase().includes(filters.search.toLowerCase()) : true;

            return matchCategories && matchAgeGroups && matchGenders && matchPrice && matchSearch;
        });

        setFilteredProducts(filtered);
    }, [filters, products]);

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    const handleSendEnquiry = (product) => {
        setSelectedProduct(product);
        setShowModal(true);

        /** Disable scrolling when the zoom modal is open */
        document.body.classList.add('scroll-lock');
    };

    const handleModalClose = () => {
        setShowModal(false);
        setSelectedProduct(null);

        /** Re-enable scrolling when the zoom modal is closed */
        document.body.classList.remove('scroll-lock');
    };

    const handleModalSubmit = (formData) => {
        console.log("Enquiry Form Submitted", formData);
        setShowModal(false);

        /** Re-enable scrolling when the zoom modal is closed */
        document.body.classList.remove('scroll-lock');
    };

    const displayedProducts = filteredProducts.slice((currentPage - 1) * productsPerPage, currentPage * productsPerPage);

    const start = (currentPage - 1) * productsPerPage + 1;
    const end = start + displayedProducts.length - 1;
    const total = filteredProducts.length;

    return (
        <div className="productlist-container">
            <div className="results-info">
                <h1 className="page-heading">Products for Sale</h1>
                {total > 0 ? (
                    <p>Showing {start}-{end} of {total} results</p>
                ) : (
                    <p>Showing 0-{end} of {total} results</p>
                )}
            </div>

            {total > 0 ? (
                <div className="product-list">
                    {displayedProducts.map(product => {
                        const discountPercentage = Math.round(((product.prices.actualSalePrice - product.prices.discountedSalePrice) / product.prices.actualSalePrice) * 100);

                        // Find the corresponding age group by product's ageGroupId
                        const ageGroup = ageGroups.find(ag => ag._id === product.ageGroupId);

                        return (
                            <div className="product-card" key={product._id}>
                                <div className="product">
                                    <Link to={`/sale/product/${product._id}`}>
                                        <div className="product-image">
                                            {product.displayImage ? 
                                                <img src={product.displayImage} alt={product.productName} /> 
                                            : "Image"}
                                            {discountPercentage > 0 && (
                                                <span className="product-discount">{discountPercentage}% Off</span>
                                            )}
                                        </div>
                                        <div className="product-detail">
                                            <div className="product-name">{product.productName}</div>
                                            <div className="product-prices">
                                                <div className="product-price">
                                                    <label>Price: </label>
                                                    <span className="product-rent-price">Rs. {product.prices.discountedSalePrice}/-</span>
                                                    {product.prices.discountedSalePrice !== product.prices.actualSalePrice && (
                                                        <span className="product-actual-price">(Rs. {product.prices.actualSalePrice}/-)</span>
                                                    )}
                                                </div>
                                            </div>
                                            <div className="product-agegroup">
                                                <label>For Age: </label>
                                                <span>{ageGroup ? ageGroup.publicName : "N/A"}</span>
                                            </div>
                                        </div>
                                    </Link>

                                    <div className="product-buttons">
                                        <Link to={`/sale/product/${product._id}`} className="view-details-button"><i className='bx bx-info-circle'></i> <span>View Details</span></Link>
                                        <button className="view-details-button" onClick={() => handleSendEnquiry(product)}><i className='bx bx-send' ></i> <span>Send Enquiry</span></button>
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                </div>
            ) : (
                <div className="no-products-found">
                    <p>No products found matching the current filters. Please try adjusting your search.</p>
                </div>
            )}

            {total > 0 && (
                <Pagination
                    totalItems={total}
                    itemsPerPage={productsPerPage}
                    currentPage={currentPage}
                    onPageChange={handlePageChange}
                />
            )}

            <EnquiryModalSale
                show={showModal}
                onClose={handleModalClose}
                productDetails={selectedProduct ? {
                    productCode: selectedProduct.productCode,
                    productImage: selectedProduct.displayImage,
                    productName: selectedProduct.productName,
                    price: selectedProduct.prices.discountedSalePrice,
                    ageGroup: ageGroups.find(ag => ag._id === selectedProduct.ageGroupId)?.publicName || "N/A"
                } : null}
                onSubmit={handleModalSubmit}
            />
        </div>
    );
};

export default ProductListComponent;