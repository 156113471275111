const ageGroups = [
    {
        "_id": "66c9bdb5720ce43748903307",
        "minRange": 0,
        "maxRange": 3,
        "publicName": "0 - 3 months",
        "image": "/uploads/agegroups/age-group-0-3.png",
        "description": "",
        "sortNumber": 1,
        "inUse": 1
    },
    {
        "_id": "66ca2c900203c22d7b5534dd",
        "minRange": 3,
        "maxRange": 6,
        "publicName": "3 - 6 months",
        "image": "/uploads/agegroups/age-group-3-6.png",
        "description": "",
        "sortNumber": 2,
        "inUse": 1
    },
    {
        "_id": "66ca2c98b721b6bc7bf75375",
        "minRange": 6,
        "maxRange": 9,
        "publicName": "6 - 9 months",
        "image": "/uploads/agegroups/age-group-6-9.png",
        "description": "",
        "sortNumber": 3,
        "inUse": 1
    },
    {
        "_id": "66ca2c9f4bb2578330270a96",
        "minRange": 9,
        "maxRange": 12,
        "publicName": "9 - 12 months",
        "image": "/uploads/agegroups/age-group-9-12.png",
        "description": "",
        "sortNumber": 4,
        "inUse": 1
    }
];

module.exports = ageGroups;