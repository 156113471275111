import React, { useEffect } from 'react';

/** Import CSS */
import '../assets/styles/global.scss';
import '../assets/styles/productdetail.scss';

/** Import Common Component */
import MenuComponent from '../components/common/MenuComponent';
import FooterComponent from '../components/common/FooterComponent';

/** Import Component */
import ProductDetailComponent from '../components/productdetailsale/ProductDetailComponent';


function ProductDetail() {
    /** Redirect to top on page load */
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div className='main-body-section'>
            <MenuComponent />

            <div className='wcm-container first-section-innerpage'>
                <div className='product-detail-main'>
                    <ProductDetailComponent />
                </div>
            </div>

            <FooterComponent />
        </div>
    )
}

export default ProductDetail;