import React, { useState } from "react";
import ReCAPTCHA from 'react-google-recaptcha';
import emailjs from 'emailjs-com';
import "../assets/styles/global.scss";

const EnquiryModal = ({ show, onClose, productDetails, onSubmit }) => {
    const [showPopup, setShowPopup] = useState(false);
    const [recaptchaToken, setRecaptchaToken] = useState(null);
    const [errors, setErrors] = useState({});
    const [isAccordionOpen, setIsAccordionOpen] = useState(false); // State for accordion

    if (!show) {
        return null;
    }

    const handleOverlayClick = (event) => {
        if (event.target.className === "modal-overlay") {
            onClose();
        }
    };

    const toggleAccordion = () => {
        setIsAccordionOpen(!isAccordionOpen);
    };

    const validateForm = (formData) => {
        let formErrors = {};

        // Full name validation
        if (!formData.fullName) {
            formErrors.fullName = "Full name is required.";
        }

        // Email validation
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!formData.email) {
            formErrors.email = "Email is required.";
        } else if (!emailRegex.test(formData.email)) {
            formErrors.email = "Please enter a valid email address.";
        }

        // Mobile number validation
        const mobileRegex = /^[6-9]\d{9}$/;
        if (!formData.mobile) {
            formErrors.mobile = "Mobile number is required.";
        } else if (!mobileRegex.test(formData.mobile)) {
            formErrors.mobile = "Please enter a valid 10-digit Indian mobile number.";
        }

        // Rent for validation (numeric and less than or equal to 10)
        const rentFor = parseInt(formData.rentFor, 10);
        if (isNaN(rentFor) || rentFor <= 0 || rentFor > 10) {
            formErrors.rentFor = "Rent duration must be a number between 1 and 10 days.";
        }

        // Rent for validation (numeric and less than or equal to 6)
        const deliveryPincode = parseInt(formData.deliveryPincode, 10);
        if (isNaN(deliveryPincode) || deliveryPincode <= 0 || deliveryPincode > 6) {
            formErrors.deliveryPincode = "delivery pincode must be a number between 1 and 6.";
        }

        // User message validation (max 500 characters)
        if (formData.userMessage && formData.userMessage.length > 500) {
            formErrors.userMessage = "Message should not exceed 500 characters.";
        }

        return formErrors;
    };

    const handleSubmit = (event) => {
        event.preventDefault();

        const formData = {
            fullName: event.target.fullName.value,
            email: event.target.email.value,
            mobile: event.target.mobile.value,
            rentFor: event.target.rentFor.value,
            deliveryPincode: event.target.deliveryPincode.value,
            userMessage: event.target.userMessage.value,
            productId: productDetails.productId,
            productName: productDetails.productName,
            price: productDetails.price,
            ageGroup: productDetails.ageGroup,
            recaptchaToken: recaptchaToken,
        };

        const formErrors = validateForm(formData);
        if (Object.keys(formErrors).length > 0) {
            setErrors(formErrors);
            return;
        }

        // Send the email using Email.js
        emailjs.send(
            process.env.REACT_APP_EMAILJS_SERVICE_ID,
            process.env.REACT_APP_EMAILJS_PRODUCT_ENQUIRY_TEMPLATE_ID,
            formData,
            process.env.REACT_APP_EMAILJS_PUBLIC_KEY
        )
            .then((response) => {
                console.log('Email sent successfully!', response.status, response.text);
                setShowPopup(true);
                setTimeout(() => {
                    setShowPopup(false);
                }, 5000);
                onSubmit(formData);
            })
            .catch((error) => {
                console.error('Failed to send email:', error);
            });
    };

    const handleRecaptchaChange = (token) => {
        setRecaptchaToken(token);
    };

    return (
        <div className="enquiry-modal modal-overlay" onClick={handleOverlayClick}>
            <div className="modal-content">
                <h2>Send Enquiry</h2>
                <span className="modal-close-icon"><i className='bx bx-x' onClick={onClose}></i></span>
                <form onSubmit={handleSubmit}>
                    <div className="form-group-section">
                        <div className="form-group-section-inner">
                            <div className="product-details-card">
                                <h2 onClick={toggleAccordion} className="accordion-title">Product Details</h2>
                                <div className={`product-details ${isAccordionOpen ? 'open' : 'closed'}`}>
                                    <div className="detail-row">
                                        <strong>Product Photo:</strong>
                                        <span>
                                            {productDetails.productImage && (
                                                <div className="product-image">
                                                    <img src={productDetails.productImage} alt={productDetails.productName} />
                                                </div>
                                            )}
                                        </span>
                                    </div>
                                    <div className="detail-row">
                                        <strong>Product Code:</strong> <span>{productDetails.productCode}</span>
                                    </div>
                                    <div className="detail-row">
                                        <strong>Name:</strong> <span>{productDetails.productName}</span>
                                    </div>
                                    <div className="detail-row">
                                        <strong>Price:</strong> <span>Rs. {productDetails.price}/-</span>
                                    </div>
                                    <div className="detail-row">
                                        <strong>Age Group:</strong> <span>{productDetails.ageGroup}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="form-group-section-inner">
                            <div className="form-group">
                                <label htmlFor="fullName"><strong>Full Name:</strong></label>
                                <input type="text" id="fullName" name="fullName" placeholder="Enter Full Name" required />
                                {errors.fullName && <p className="error-message">{errors.fullName}</p>}
                            </div>
                            <div className="form-group">
                                <label htmlFor="email"><strong>Email:</strong></label>
                                <input type="email" id="email" name="email" placeholder="Enter Email Address" required />
                                {errors.email && <p className="error-message">{errors.email}</p>}
                            </div>
                            <div className="form-group">
                                <label htmlFor="mobile"><strong>Mobile:</strong></label>
                                <input type="text" id="mobile" name="mobile" placeholder="Enter Mobile Number" required />
                                {errors.mobile && <p className="error-message">{errors.mobile}</p>}
                            </div>
                            <div className="form-group">
                                <label htmlFor="deliveryPincode"><strong>Delivery Pincode:</strong></label>
                                <input type="text" id="deliveryPincode" name="deliveryPincode" placeholder="Your delivery pincode" required />
                                {errors.deliveryPincode && <p className="error-message">{errors.deliveryPincode}</p>}
                            </div>
                            <div className="form-group">
                                <label htmlFor="userMessage"><strong>Message:</strong></label>
                                <textarea
                                    id="userMessage"
                                    rows={5}
                                    placeholder="Enter any additional details"
                                />
                            </div>
                        </div>
                    </div>

                    <ReCAPTCHA
                        sitekey={process.env.REACT_APP_GOOGLE_RECAPTCHA_TOKEN}
                        onChange={handleRecaptchaChange}
                        size="invisible"
                    />

                    <button type="submit">Send Request</button>
                </form>

                {showPopup && (
                    <div className="popup-success">
                        <p>Email sent successfully!</p>
                    </div>
                )}
            </div>
        </div>
    );
};

export default EnquiryModal;