import React, { useState, useEffect } from "react";
import ReCAPTCHA from 'react-google-recaptcha';
import emailjs from 'emailjs-com';
import "../assets/styles/global.scss";

const EnquiryModal = ({ show, onClose, productDetails, onSubmit }) => {
    const [showPopup, setShowPopup] = useState(false);
    const [recaptchaToken, setRecaptchaToken] = useState(null);
    const [errors, setErrors] = useState({});
    const [isAccordionOpen, setIsAccordionOpen] = useState(false); // State for accordion
    const [minDate, setMinDate] = useState('');
    const [maxDate, setMaxDate] = useState('');
    const [defaultDate, setDefaultDate] = useState('');
    const [loading, setLoading] = useState(false);
    const [formData, setFormData] = useState({
        fullName: "",
        email: "",
        mobile: "",
        deliveryPincode: ""
    });

    // Prefill form data and set min/max dates when component mounts
    useEffect(() => {
        if (show) {
            // Clear errors when modal opens
            setErrors({});

            // Prefill form data from localStorage if available
            const storedData = JSON.parse(localStorage.getItem("userDetails"));
            if (storedData) {
                setFormData(storedData);
            }

            // Calculate the current date + 3 days
            const today = new Date();
            const currentPlusTwoDays = new Date();
            currentPlusTwoDays.setDate(today.getDate() + 4);

            // Set min date to current date + 3 days
            const formattedMinDate = currentPlusTwoDays.toISOString().split("T")[0];
            setMinDate(formattedMinDate);
            setDefaultDate(formattedMinDate);

            // Set max date to current date + 2 months
            const twoMonthsLater = new Date();
            twoMonthsLater.setMonth(today.getMonth() + 3);
            twoMonthsLater.setDate(0); // Last day of the previous month (which will be end of 2nd month)
            const formattedMaxDate = twoMonthsLater.toISOString().split("T")[0];
            setMaxDate(formattedMaxDate);
        }
    }, [show]);

    if (!show) {
        return null;
    }

    const handleOverlayClick = (event) => {
        if (event.target.classList.contains('modal-overlay')) {
            onClose();
        }
    };

    const toggleAccordion = () => {
        setIsAccordionOpen(!isAccordionOpen);
    };

    const validateForm = (formData) => {
        let formErrors = {};
    
        /** Full name validation */
        if (!formData.fullName) {
            formErrors.fullName = "Full name is required.";
        }
    
        /** Email validation */
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!formData.email) {
            formErrors.email = "Email is required.";
        } else if (!emailRegex.test(formData.email)) {
            formErrors.email = "Please enter a valid email address.";
        }
    
        /** Mobile number validation */
        const mobileRegex = /^[6-9]\d{9}$/;
        if (!formData.mobile) {
            formErrors.mobile = "Mobile number is required.";
        } else if (!mobileRegex.test(formData.mobile.trim())) {
            formErrors.mobile = "Please enter a valid 10-digit Indian mobile number.";
        }
    
        /** Rent date validation */
        if (!formData.rentDate) {
            formErrors.rentDate = "Rent date is required.";
        }
    
        /** Rent duration validation (numeric and between 1 and 10) */
        const rentDuration = parseInt(formData.rentDuration, 10);
        if (isNaN(rentDuration) || rentDuration <= 0 || rentDuration > 10) {
            formErrors.rentDuration = "Rent duration must be a number between 1 and 10 days.";
        }
    
        /** Delivery pincode validation (must be exactly 6 digits) */
        const pincodeRegex = /^\d{6}$/;
        if (!pincodeRegex.test(formData.deliveryPincode)) {
            formErrors.deliveryPincode = "Please enter a valid 6-digit pincode.";
        }
    
        /** User message validation (max 500 characters) */
        if (formData.userMessage && formData.userMessage.length > 500) {
            formErrors.userMessage = "Message should not exceed 500 characters.";
        }
    
        return formErrors;
    };

    const handleInputChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };

    const handleSubmit = (event) => {
        event.preventDefault();

        /** Show loader */
        setLoading(true);

        const formData = {
            fullName: event.target.fullName.value,
            email: event.target.email.value,
            mobile: event.target.mobile.value,
            rentDuration: event.target.rentDuration.value,
            deliveryPincode: event.target.deliveryPincode.value,
            rentDate: event.target.rentDate.value,
            userMessage: event.target.userMessage.value,
            productId: productDetails.productId,
            productCode: productDetails.productCode,
            productName: productDetails.productName,
            rentPrice: productDetails.rentPrice,
            depositPrice: productDetails.depositPrice,
            ageGroup: productDetails.ageGroup,
            recaptchaToken: recaptchaToken,
        };

        const formErrors = validateForm(formData);
        if (Object.keys(formErrors).length > 0) {
            setErrors(formErrors);
            /** Show loader */
            setLoading(false);
            return;
        }

        /** Send the email using Email.js */
        emailjs.send(
            process.env.REACT_APP_EMAILJS_SERVICE_ID,
            process.env.REACT_APP_EMAILJS_PRODUCT_ENQUIRY_TEMPLATE_ID,
            formData,
            process.env.REACT_APP_EMAILJS_PUBLIC_KEY
        )
        .then((response) => {
            console.log('Email sent successfully!', response.status, response.text);

            // Clear error messages when popup is shown
            setErrors({});

            setShowPopup(true);

            setTimeout(() => {
                setShowPopup(false);
            }, 5000);
            
            onSubmit(formData);
            setLoading(false); // Hide loader after response

            // Save form data to localStorage
            localStorage.setItem("userDetails", JSON.stringify(formData));
        })
        .catch((error) => {
            console.error('Failed to send email:', error);
            setLoading(false); // Hide loader after respons
        });
    };

    const handleRecaptchaChange = (token) => {
        setRecaptchaToken(token);
    };

    const clearForm = () => {
        // Clear form data and localStorage
        setFormData({
            fullName: "",
            email: "",
            mobile: "",
            deliveryPincode: ""
        });
        localStorage.removeItem("userDetails");
        setErrors({});
    };

    return (
        <div className="enquiry-modal modal-overlay" onClick={handleOverlayClick}>
            <div className="modal-content">
                <h2>Send Enquiry</h2>
                <span className="modal-close-icon"><i className='bx bx-x' onClick={onClose}></i></span>
                <form onSubmit={handleSubmit}>
                    <div className="form-group-section">
                        <div className="form-group-section-inner">
                            <div className="product-details-card">
                                <h2 onClick={toggleAccordion} className="accordion-title">Product Details</h2>
                                <div className={`product-details ${isAccordionOpen ? 'open' : 'closed'}`}>
                                    <div className="detail-row">
                                        <strong>Product Photo:</strong>
                                        <span>
                                            {productDetails.productImage && (
                                                <div className="product-image">
                                                    <img src={productDetails.productImage} alt={productDetails.productName} />
                                                </div>
                                            )}
                                        </span>
                                    </div>
                                    <div className="detail-row">
                                        <strong>Product Code:</strong> <span>{productDetails.productCode}</span>
                                    </div>
                                    <div className="detail-row">
                                        <strong>Name:</strong> <span>{productDetails.productName}</span>
                                    </div>
                                    <div className="detail-row">
                                        <strong>Rent <span>(per day)</span>:</strong> <span>Rs. {productDetails.rentPrice}/-</span>
                                    </div>
                                    <div className="detail-row">
                                        <strong>Deposit:</strong> <span>Rs. {productDetails.depositPrice}/-</span>
                                    </div>
                                    <div className="detail-row">
                                        <strong>Age Group:</strong> <span>{productDetails.ageGroup}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="form-group-section-inner">
                            <div className="form-group">
                                <label htmlFor="fullName"><strong>Full Name:</strong></label>
                                <input type="text" id="fullName" name="fullName" value={formData.fullName} onChange={handleInputChange} placeholder="Enter Full Name" />
                                {errors.fullName && <p className="error-message">{errors.fullName}</p>}
                            </div>
                            <div className="form-group">
                                <label htmlFor="email"><strong>Email:</strong></label>
                                <input type="email" id="email" name="email" value={formData.email} onChange={handleInputChange} placeholder="Enter Email Address" />
                                {errors.email && <p className="error-message">{errors.email}</p>}
                            </div>
                            <div className="form-group">
                                <label htmlFor="mobile"><strong>Mobile:</strong></label>
                                <input type="number" id="mobile" name="mobile" value={formData.mobile} onChange={handleInputChange} placeholder="Enter Mobile Number" />
                                {errors.mobile && <p className="error-message">{errors.mobile}</p>}
                            </div>
                            <div className="form-group">
                                <label htmlFor="rentDate"><strong>Rent Date:</strong></label>
                                <input 
                                    type="date" 
                                    id="rentDate" 
                                    name="rentDate" 
                                    min={minDate}
                                    max={maxDate}
                                    defaultValue={defaultDate} 
                                 
                                />
                                {errors.rentDate && <p className="error-message">{errors.rentDate}</p>}
                            </div>
                            <div className="form-group">
                                <label htmlFor="rentDuration"><strong>Duration <span>(in days)</span>:</strong></label>
                                <input type="number" id="rentDuration" name="rentDuration" placeholder="Renting duration" />
                                {errors.rentDuration && <p className="error-message">{errors.rentDuration}</p>}
                            </div>
                            <div className="form-group">
                                <label htmlFor="deliveryPincode"><strong>Delivery Pincode:</strong></label>
                                <input type="number" id="deliveryPincode" name="deliveryPincode" value={formData.deliveryPincode} onChange={handleInputChange} placeholder="Your delivery pincode" />
                                {errors.deliveryPincode && <p className="error-message">{errors.deliveryPincode}</p>}
                            </div>
                            <div className="form-group">
                                <label htmlFor="userMessage"><strong>Message:</strong></label>
                                <textarea
                                    id="userMessage"
                                    rows={5}
                                    placeholder="Enter any additional details"
                                />
                            </div>
                        </div>
                    </div>

                    <ReCAPTCHA
                        sitekey={process.env.REACT_APP_GOOGLE_RECAPTCHA_TOKEN}
                        onChange={handleRecaptchaChange}
                        size="invisible"
                    />

                    <div className="enquiry-form-buttons">
                        <button type="button" className="clear-form" onClick={clearForm}>Clear Form</button>
                        <button type="submit" disabled={loading}>{loading ? 'Sending...' : 'Send Request'}</button>
                    </div>
                </form>

                {showPopup && (
                    <div className="popup-success">
                        <p>Email sent successfully!</p>
                    </div>
                )}
            </div>
        </div>
    );
};

export default EnquiryModal;