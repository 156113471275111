import React from "react";
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import UsePageTracking from '../utils/usePageTracking';

/** Import pages */
import Home from '../pages/Home';
import About from '../pages/AboutUs';
import Service from '../pages/Service';
import Contact from '../pages/ContactUs';
import ProductList from '../pages/ProductList';
import ProductDetail from '../pages/ProductDetail';
import ProductListSale from '../pages/ProductListSale';
import ProductDetailSale from '../pages/ProductDetailSale';
import HowItWorks from '../pages/HowItWorks';
import ShipmentAndReturn from '../pages/ShipmentAndReturn';

function AppRouter() {
    

    return (
        <Router>
            <UsePageTracking /> {/** Call the tracking hook for google analytics */}
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/about" element={<About />} />
                <Route path="/service" element={<Service />} />
                <Route path="/contact" element={<Contact />} />
                <Route path="/rent/products" element={<ProductList />} />
                <Route path="/sale/products" element={<ProductListSale />} />
                <Route path="/rent/product/:productId" element={<ProductDetail />} />
                <Route path="/sale/product/:productId" element={<ProductDetailSale />} />
                <Route path="/howitworks" element={<HowItWorks />} />
                <Route path="/shipmentandreturns" element={<ShipmentAndReturn />} />
            </Routes>
        </Router>
    );
}

export default AppRouter;