import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import EnquiryModalRent from "../../utils/EnquiryModalRent";
import productsService from "../../services/productsRent";
import ageGroupService from "../../services/ageGroups";

const TopProductComponent = () => {
    const [filteredProducts, setFilteredProducts] = useState([]);
    const [ageGroups, setAgeGroups] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [selectedProduct, setSelectedProduct] = useState(null);
    const [showSuccessMessage, setShowSuccessMessage] = useState(false);

    useEffect(() => {
        /** Filter products based on the conditions and sort them by sortNumber */
        const topProducts = productsService
        .filter(product => product.isTopProduct === 1 && product.showOnWeb === 1 && product.inUse === 1)
        .sort((a, b) => a.sortNumber - b.sortNumber);

        setFilteredProducts(topProducts);

        /** Set the products and age groups */
        setAgeGroups(ageGroupService);
    }, []);

    const handleSendEnquiry = (product) => {
        setSelectedProduct(product);
        setShowModal(true);

        // Disable scrolling when the modal is open
        document.body.classList.add('scroll-lock');
    };

    const handleModalClose = () => {
        setShowModal(false);
        setSelectedProduct(null);

        // Re-enable scrolling when the modal is closed
        document.body.classList.remove('scroll-lock');
    };

    const handleModalSubmit = (formData) => {
        setShowModal(false);
        setShowSuccessMessage(true);
    
        // Hide the success message after 10 seconds
        setTimeout(() => {
            setShowSuccessMessage(false);
        }, 10000);
    
        // Re-enable scrolling when the modal is closed
        document.body.classList.remove('scroll-lock');
    };

    return (
        <div className="list-container home-top-products">
            <h1 className="home-page-heading">Top Products for Rent</h1>

            {showSuccessMessage && (
                <div className="success-message">
                    <p>Your enquiry was sent successfully!</p>
                </div>
            )}

            {filteredProducts.length > 0 ? (
                <div className="product-list">
                    {filteredProducts.map(product => {
                        const discountPercentage = Math.round(((product.prices.actualRentPrice - product.prices.discountedRentPrice) / product.prices.actualRentPrice) * 100);

                        // Find the corresponding age group by product's ageGroupId
                        const ageGroup = ageGroups.find(ag => ag._id === product.ageGroupId);

                        return (
                            <div className="product-card" key={product._id}>
                                <div className="product">
                                    <Link to={`/rent/product/${product._id}`}>
                                        <div className="product-image">
                                            {product.displayImage ? 
                                                <img src={product.displayImage} alt={product.productName} /> 
                                            : "Image"}
                                            {discountPercentage > 0 && (
                                                <span className="product-discount">{discountPercentage}% Off</span>
                                            )}
                                        </div>
                                        <div className="product-detail">
                                            <div className="product-name">{product.productName}</div>
                                            <div className="product-prices">
                                                <div className="product-price">
                                                    <label>Rent: </label>
                                                    <span className="product-rent-price">Rs. {product.prices.discountedRentPrice}/-</span>
                                                    {product.prices.discountedRentPrice !== product.prices.actualRentPrice && (
                                                        <span className="product-actual-price">(Rs. {product.prices.actualRentPrice}/-)</span>
                                                    )}
                                                </div>
                                                <div className="product-deposit-price">
                                                    <label>Deposit: </label>
                                                    <span>Rs. {product.prices.depositPrice}/-</span>
                                                </div>
                                            </div>
                                            <div className="product-agegroup">
                                                <label>For Age: </label>
                                                <span>{ageGroup ? ageGroup.publicName : "N/A"}</span>
                                            </div>
                                        </div>
                                    </Link>

                                    <div className="product-buttons">
                                        <Link to={`/rent/product/${product._id}`} className="view-details-button"><i className='bx bx-info-circle'></i> <span>View Details</span></Link>
                                        <button className="view-details-button" onClick={() => handleSendEnquiry(product)}><i className='bx bx-send' ></i> <span>Send Enquiry</span></button>
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                </div>
            ) : (
                <div className="no-products-found">
                    <p>No products found.</p>
                </div>
            )}

            <EnquiryModalRent
                show={showModal}
                onClose={handleModalClose}
                productDetails={selectedProduct ? {
                    productCode: selectedProduct.productCode,
                    productImage: selectedProduct.displayImage,
                    productName: selectedProduct.productName,
                    rentPrice: selectedProduct.prices.discountedRentPrice,
                    depositPrice: selectedProduct.prices.depositPrice,
                    ageGroup: ageGroups.find(ag => ag._id === selectedProduct.ageGroupId)?.publicName || "N/A"
                } : null}
                onSubmit={handleModalSubmit}
            />
        </div>
    );
};

export default TopProductComponent;