import React, { useEffect } from "react";

const HowItWorksComponent = () => {
    useEffect(() => {
        const handleScroll = () => {
          const steps = document.querySelectorAll('.step');
          const windowHeight = window.innerHeight / 2.5; // Adjust to check the middle of the viewport
    
          steps.forEach((step, index) => {
            const rect = step.getBoundingClientRect();
            const lineSegment = document.getElementById('step-line-' + (index + 1));
    
            // Reset all first to ensure only the current section is highlighted
            lineSegment.style.backgroundColor = '#83cfdb';
            step.classList.remove('highlighted');
    
            // Check if the middle of the step is near the middle of the viewport
            if (rect.top < windowHeight && rect.bottom > windowHeight) {
              lineSegment.style.backgroundColor = '#dc4b52';
              step.classList.add('highlighted');
            }
          });
        };
    
        window.addEventListener('scroll', handleScroll);
    
        // Clean up the event listener on component unmount
        return () => {
          window.removeEventListener('scroll', handleScroll);
        };
      }, []);

    return(
        <div className="content-main">
            <div className="how-it-works-container">
                <div className="header">
                    <h1>How it Works</h1>
                </div>
                <div className="steps">
                    <div className="line"></div>
                    <div className="step">
                        <div className="step-icon">1</div>
                        <div className="step-line" id="step-line-1"></div>
                        <div className="step-content">
                            <h3>Choose Your Items</h3>
                            <p>Select the items you want to rent and pay the rental fee along with a refundable deposit.</p>
                        </div>
                    </div>
                    <div className="step">
                        <div className="step-icon">2</div>
                        <div className="step-line" id="step-line-2"></div>
                        <div className="step-content">
                            <h3>Delivery to Your Doorstep</h3>
                            <p>We deliver the rented items to your home quickly and safely.</p>
                        </div>
                    </div>
                    <div className="step">
                        <div className="step-icon">3</div>
                        <div className="step-line" id="step-line-3"></div>
                        <div className="step-content">
                            <h3>Enjoy and Return</h3>
                            <p>Use the items and then pack them for return when you're done.</p>
                        </div>
                    </div>
                    <div className="step">
                        <div className="step-icon">4</div>
                        <div className="step-line" id="step-line-4"></div>
                        <div className="step-content">
                            <h3>Quality Check</h3>
                            <p>We inspect the returned items for any damage or stains.</p>
                        </div>
                    </div>
                    <div className="step">
                        <div className="step-icon">5</div>
                        <div className="step-line" id="step-line-5"></div>
                        <div className="step-content">
                            <h3>Get Your Deposit Back</h3>
                            <p>Receive your refundable deposit after the items pass our quality check.</p>
                        </div>
                    </div>
                    <div className="step">
                        <div className="step-icon">6</div>
                        <div className="step-line" id="step-line-6"></div>
                        <div className="step-content">
                            <h3>Cleaning and Sanitizing</h3>
                            <p>We professionally clean and sanitize the items for the next user.</p>
                        </div>
                    </div>
                    <div className="step">
                        <div className="step-icon">7</div>
                        <div className="step-line" id="step-line-7"></div>
                        <div className="step-content">
                            <h3>Ready for Rent Again</h3>
                            <p>The items are listed again, ready for the next customer to rent.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default HowItWorksComponent;