const products = [
    {
        "_id": "66cf7c7568fe7b7501b5363a",
        "productCode": 20001,
        "productName": "",
        "displayImage": "/uploads/buy/product_display_image/",
        "description": "",
        "colors": [
            {
                "colorCode": "#af0c13",
                "inUse": 1
            },
            {
                "colorCode": "#e6cc46",
                "inUse": 1
            }
        ],
        "prices":  {
            "discountedSalePrice": 0,
            "actualSalePrice": 0,
            "depositPrice": 0,
            "inUse": 1
        },
        "images": [
            {
                "image": "/uploads/buy/products/",
                "imageTitle": "Image AF",
                "inUse": 1
            },
            {
                "image": "/uploads/buy/products/",
                "imageTitle": "Image BF",
                "inUse": 1
            },
            {
                "image": "/uploads/buy/products/",
                "imageTitle": "Image CF",
                "inUse": 1
            }
        ],
        "attributes": [
            {
                "attributeTitle": "Origin",
                "attributeValue": "Made in India"
            },
            {
                "attributeTitle": "Designed by",
                "attributeValue": "We Craft Memories"
            },
            {
                "attributeTitle": "Size",
                "attributeValue": "Height: 14 in, Width: 10 in"
            }
        ],
        "categoryId": "",
        "ageGroupId": "",
        "genderId": "",
        "showOnWeb": 1,
        "isTopProduct": 0,
        "sortNumber": 1,
        "inUse": 1
    }
];

module.exports = products;