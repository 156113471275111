import React, { useState } from "react";

const Accordion = ({ title, children, isOpen: defaultIsOpen }) => {
    const [isOpen, setIsOpen] = useState(defaultIsOpen);

    return (
        <div className="accordion">
            <div className="accordion-header" onClick={() => setIsOpen(!isOpen)}>
                <span className="accordion-title">{title}</span>
                <span className="accordion-icon">
                    {isOpen ? <i className='bx bx-chevron-up'></i> : <i className='bx bx-chevron-down'></i>}
                </span>
            </div>
            {isOpen && <div className="accordion-body">{children}</div>}
        </div>
    );
};

export default Accordion;