import React, { useState, useEffect, useCallback } from "react";
import { useLocation } from "react-router-dom";

/** Import Utils */
import Accordion from "../../utils/Accordion";

/** Import Services */
import categoriesService from "../../services/categories";
import ageGroupsService from "../../services/ageGroups";
import gendersService from "../../services/genders";
import productsService from "../../services/productsSale"; // Assuming productsSale for sale products

const ProductFilterComponent = ({ filters, setFilters }) => {
    const location = useLocation();
    const [categories, setCategories] = useState([]);
    const [ageGroups, setAgeGroups] = useState([]);
    const [genders, setGenders] = useState([]);
    const [priceRange, setPriceRange] = useState([0, 700]);

    // Function to clear filters, wrapped in useCallback to prevent re-creation on each render
    const clearAllFilters = useCallback(() => {
        const initialFilters = {
            categories: [],
            ageGroups: [],
            genders: [],
            price: { min: 0, max: 700 },
            search: ""
        };
        setFilters(initialFilters);
        setPriceRange([0, 700]);
        localStorage.removeItem("productFilters");
    }, [setFilters]);

    // Clear filters on path change
    useEffect(() => {
        if (location.pathname.includes("/sale")) {
            clearAllFilters();
        }
    }, [location.pathname, clearAllFilters]);

    useEffect(() => {
        const availableCategories = categoriesService.filter(category =>
            productsService.some(product => product.categoryId === category._id && product.inUse === 1 && product.showOnWeb === 1)
        );

        const availableAgeGroups = ageGroupsService.filter(ageGroup =>
            productsService.some(product => product.ageGroupId === ageGroup._id && product.inUse === 1 && product.showOnWeb === 1)
        );

        const availableGenders = gendersService.filter(gender =>
            productsService.some(product => product.genderId === gender._id && product.inUse === 1 && product.showOnWeb === 1)
        );

        setCategories(availableCategories);
        setAgeGroups(availableAgeGroups);
        setGenders(availableGenders);

        // Load filters from localStorage
        const savedFilters = JSON.parse(localStorage.getItem("productFilters"));
        if (savedFilters) {
            setFilters(savedFilters);
            setPriceRange(savedFilters.price ? [savedFilters.price.min, savedFilters.price.max] : [0, 700]);
        }
    }, [setFilters]);

    useEffect(() => {
        // Save filters to localStorage whenever they change
        localStorage.setItem("productFilters", JSON.stringify(filters));
    }, [filters]);

    useEffect(() => {
        // Apply filters based on the state passed through the location (e.g., from the menu component)
        if (location.state) {
            const { categoryId, ageGroupId } = location.state;

            if (categoryId) {
                setFilters(prevFilters => ({
                    ...prevFilters,
                    categories: [...prevFilters.categories, categoryId]
                }));
            }

            if (ageGroupId) {
                setFilters(prevFilters => ({
                    ...prevFilters,
                    ageGroups: [...prevFilters.ageGroups, ageGroupId]
                }));
            }
        }
    }, [location.state, setFilters]);

    const handleCategoryChange = (categoryId) => {
        setFilters(prevFilters => ({
            ...prevFilters,
            categories: prevFilters.categories.includes(categoryId)
                ? prevFilters.categories.filter(id => id !== categoryId)
                : [...prevFilters.categories, categoryId]
        }));
    };

    const handleAgeGroupChange = (ageGroupId) => {
        setFilters(prevFilters => ({
            ...prevFilters,
            ageGroups: prevFilters.ageGroups.includes(ageGroupId)
                ? prevFilters.ageGroups.filter(id => id !== ageGroupId)
                : [...prevFilters.ageGroups, ageGroupId]
        }));
    };

    const handleGenderChange = (genderId) => {
        setFilters(prevFilters => ({
            ...prevFilters,
            genders: prevFilters.genders.includes(genderId)
                ? prevFilters.genders.filter(id => id !== genderId)
                : [...prevFilters.genders, genderId]
        }));
    };

    const handlePriceChange = (min, max) => {
        setPriceRange([min, max]);
        setFilters(prevFilters => ({
            ...prevFilters,
            price: { min, max }
        }));
    };

    const handleSearch = (e) => {
        setFilters(prevFilters => ({
            ...prevFilters,
            search: e.target.value
        }));
    };

    const clearFilter = (type, id) => {
        setFilters(prevFilters => ({
            ...prevFilters,
            [type]: prevFilters[type].filter(item => item !== id)
        }));
    };

    return (
        <div className="filter-container">
            {(filters.search || filters.categories.length > 0 || filters.ageGroups.length > 0 || filters.genders.length > 0 || (filters.price.min !== 0 || filters.price.max !== 700)) && (
                <div className="applied-filter-title">Applied Filters</div>
            )}

            <div className="applied-filter">
                {filters.search && (
                    <div className="applied-filters">
                        <span className="filter-tag">
                            {filters.search} <span className="filter-remove" onClick={() => setFilters(prevFilters => ({ ...prevFilters, search: "" }))}>x</span>
                        </span>
                    </div>
                )}

                {filters.categories.length > 0 && (
                    <div className="applied-filters">
                        <strong>Categories:</strong>
                        <div className="applied-filters-list">
                            {filters.categories.map(categoryId => (
                                <span key={categoryId} className="filter-tag">
                                    {categories.find(cat => cat._id === categoryId)?.name} 
                                    <span className="filter-remove" onClick={() => clearFilter("categories", categoryId)}>x</span>
                                </span>
                            ))}
                        </div>
                    </div>
                )}

                {filters.ageGroups.length > 0 && (
                    <div className="applied-filters">
                        <strong>Age Groups:</strong>
                        <div className="applied-filters-list">
                            {filters.ageGroups.map(ageGroupId => (
                                <span key={ageGroupId} className="filter-tag">
                                    {ageGroups.find(ag => ag._id === ageGroupId)?.publicName} 
                                    <span className="filter-remove" onClick={() => clearFilter("ageGroups", ageGroupId)}>x</span>
                                </span>
                            ))}
                        </div>
                    </div>
                )}

                {filters.genders.length > 0 && (
                    <div className="applied-filters">
                        <strong>Genders:</strong>
                        <div className="applied-filters-list">
                            {filters.genders.map(genderId => (
                                <span key={genderId} className="filter-tag">
                                    {genders.find(gen => gen._id === genderId)?.name} 
                                    <span className="filter-remove" onClick={() => clearFilter("genders", genderId)}>x</span>
                                </span>
                            ))}
                        </div>
                    </div>
                )}

                {(filters.price.min !== 0 || filters.price.max !== 700) && (
                    <div className="applied-filters">
                        <strong>Price Range:</strong>
                        <span className="filter-tag">
                            Rs. {filters.price.min} - Rs. {filters.price.max}
                            <span className="filter-remove" onClick={() => handlePriceChange(0, 700)}>x</span>
                        </span>
                    </div>
                )}

                {(filters.categories.length > 0 || filters.ageGroups.length > 0 || filters.genders.length > 0 || filters.search || filters.price.min !== 0 || filters.price.max !== 700) && (
                    <div className="clear-all-filters">
                        <button onClick={clearAllFilters}>Clear all filters</button>
                    </div>
                )}
            </div>
            
            <Accordion title="Search" isOpen={true}>
                <input 
                    type="text" 
                    placeholder="Search by name..." 
                    onChange={handleSearch} 
                    className="filter-search"
                    value={filters.search}
                />
            </Accordion>

            <Accordion title="Category" isOpen={false}>
                {categories.map(category => (
                    <div key={category._id}>
                        <input 
                            type="checkbox"
                            id={`category-${category._id}`}
                            onChange={() => handleCategoryChange(category._id)} 
                            checked={filters.categories.includes(category._id)}
                        />
                        <label htmlFor={`category-${category._id}`} style={{ cursor: 'pointer' }}>{category.name}</label>
                    </div>
                ))}
            </Accordion>

            <Accordion title="Age Group" isOpen={false}>
                {ageGroups.map(ageGroup => (
                    <div key={ageGroup._id}>
                        <input 
                            type="checkbox"
                            id={`ageGroup-${ageGroup._id}`}
                            onChange={() => handleAgeGroupChange(ageGroup._id)} 
                            checked={filters.ageGroups.includes(ageGroup._id)}
                        />
                        <label htmlFor={`ageGroup-${ageGroup._id}`} style={{ cursor: 'pointer' }}>{ageGroup.publicName}</label>
                    </div>
                ))}
            </Accordion>

            <Accordion title="Gender" isOpen={false}>
                {genders.map(gender => (
                    <div key={gender._id}>
                        <input 
                            type="checkbox"
                            id={`gender-${gender._id}`}
                            onChange={() => handleGenderChange(gender._id)} 
                            checked={filters.genders.includes(gender._id)}
                        />
                        <label htmlFor={`gender-${gender._id}`} style={{ cursor: 'pointer' }}>{gender.name}</label>
                    </div>
                ))}
            </Accordion>

            <Accordion title="Sale Price Range" isOpen={false}>
                <input 
                    type="range" 
                    min="0" 
                    max="700" 
                    value={priceRange[1]} 
                    onChange={(e) => handlePriceChange(0, e.target.value)} 
                />
                <span className="range-selection-label">{`Rs. ${priceRange[0]} - Rs. ${priceRange[1]}`}</span>
            </Accordion>
        </div>
    );
};

export default ProductFilterComponent;