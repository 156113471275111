import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';

/** Import CSS */
import '../../assets/styles/global.scss';
import '../../assets/styles/menu.scss';

/** Import Logo */
import Logo from '../../assets/images/logo.png';

/** Import Services */
import ageGroups from '../../services/ageGroups';
import productsRent from '../../services/productsRent';
import productsSale from '../../services/productsSale';

const MenuComponent = () => {
    const navigate = useNavigate();
    const location = useLocation();  // Get current location

    /** Manage State */
    const [searchQuery, setSearchQuery] = useState('');
    const [searchResults, setSearchResults] = useState([]);

    /** Remove productFilters from localStorage when URL does not contain /rent/product */
    useEffect(() => {
        if (!location.pathname.includes('/rent/product')) {
            localStorage.removeItem('productFilters');
        }
    }, [location.pathname]);  // Run the effect whenever the pathname changes

    /** Function to get the actual age group name based on ageGroupId */
    const getAgeGroupName = (ageGroupId) => {
        const ageGroup = ageGroups.find(group => group._id === ageGroupId);
        return ageGroup ? ageGroup.publicName : 'Unknown Age Group';
    };

    /** Filter products based on the search query */
    useEffect(() => {
        if (searchQuery.length >= 3) {  // Wait for at least 3 characters before performing search
            const rentResults = productsRent.filter(product =>
                product.productName && product.productName.toLowerCase().includes(searchQuery.toLowerCase())
            ).map(product => ({
                productName: product.productName,
                ageGroup: getAgeGroupName(product.ageGroupId),
                type: 'Rent',
                id: product._id
            }));

            const saleResults = productsSale.filter(product =>
                product.productName && product.productName.toLowerCase().includes(searchQuery.toLowerCase())
            ).map(product => ({
                productName: product.productName,
                ageGroup: getAgeGroupName(product.ageGroupId),
                type: 'Sale',
                id: product._id
            }));

            setSearchResults([...rentResults, ...saleResults]);
        } else {
            setSearchResults([]);  // Clear results if less than 3 characters
        }
    }, [searchQuery]);

    /** Function to handle click on search result */
    const handleResultClick = (result) => {
        // Create the URL dynamically based on the product type and ID
        const url = result.type === 'Rent' ? `/rent/product/${result.id}` : `/sale/product/${result.id}`;
        navigate(url);  // Redirect to the dynamic URL
    };

    return (
        <header>
            <div className="header-content-top">
                <div className="content">
                    <span className="header-contact-info"><i className="bx bxs-phone"></i> <a href="tel:8097987769" rel="noreferrer">+91 8097987769</a></span>
                    <span className="header-contact-info"><i className="bx bxs-envelope"></i> <a href="mailto:support@wecraftmemories.com" rel="noreferrer">support@wecraftmemories.com</a></span>
                </div>
            </div>
            <div className="container">
                <strong className="logo">
                    <Link to={'/'}>
                        <img src={Logo} alt='Logo'></img>
                    </Link>
                </strong>

                <label className="open-search" htmlFor="open-search">
                    <input className="input-open-search" id="open-search" type="checkbox" name="menu" />
                    <div className="search">
                        <button className="button-search"><i className="bx bx-search"></i></button>
                        <input
                            type="text"
                            placeholder="Type at least 3 characters to search product..."
                            className="input-search"
                            value={searchQuery}
                            onChange={(e) => setSearchQuery(e.target.value)}
                        />
                    </div>

                    {/* Dropdown for showing search results */}
                    {searchQuery.length >= 3 && (
                        <ul className="search-dropdown">
                            {searchResults.length > 0 ? (
                                searchResults.map((result, index) => (
                                    <li
                                        key={index}
                                        className="search-result-item"
                                        onClick={() => handleResultClick(result)}  // Handle click event
                                    >
                                        <span className='search-dropdown-product-name'>{result.productName || 'Unnamed Product'}</span>
                                        <span className='search-dropdown-product-age'>{'(' + result.ageGroup + ')' || 'Unknown Age Group'}</span>
                                        {/* <span className={result.type === 'Rent' ? 'rent-item' : 'sale-item'}>
                                            {result.type}
                                        </span> */}
                                    </li>
                                ))
                            ) : (
                                <li className="search-result-item no-results">
                                    No products found
                                </li>
                            )}
                        </ul>
                    )}
                </label>

                <nav className="nav-content">
                    <ul className="nav-content-list">
                        <li className={`nav-content-item ${location.pathname === '/' ? 'active' : ''}`}>
                            <Link className="nav-content-link" to={'/'}>
                                <i className="bx bxs-home"></i>
                                <span>Home</span>
                            </Link>
                        </li>
                        <li className={`nav-content-item ${location.pathname.includes('/rent/product') ? 'active' : ''}`}>
                            <Link className="nav-content-link" to={'/rent/products'}>
                                <i className="bx bx-store-alt"></i>
                                <span>Products</span>
                            </Link>
                        </li>
                        <li className={`nav-content-item ${location.pathname.includes('/howitworks') ? 'active' : ''}`}>
                            <Link className="nav-content-link" to={'/howitworks'}>
                                <i className="bx bx-list-ol"></i>
                                <span>Process</span>
                            </Link>
                        </li>
                        {/* <li className={`nav-content-item ${location.pathname.includes('/sale/products') ? 'active' : ''}`}>
                            <Link className="nav-content-link" to={'/sale/products'}>
                                <i className='bx bx-shopping-bag'></i>
                                <span>Buy</span>
                            </Link>
                        </li> */}
                    </ul>
                </nav>
            </div>
        </header>
    );
}

export default MenuComponent;