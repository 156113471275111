import React, { useEffect } from 'react';

/** Import CSS */
import '../assets/styles/staticcontent.scss';

/** Import Common Component */
import MenuComponent from '../components/common/MenuComponent';
import FooterComponent from '../components/common/FooterComponent';

/** Import Component */
import ShipmentAndReturnComponent from '../components/staticcontent/ShipmentAndReturnComponent';


function ShipmentAndReturn() {
    /** Redirect to top on page load */
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div className='main-body-section'>
            <MenuComponent></MenuComponent>

            <div className='first-section-innerpage'>
                <ShipmentAndReturnComponent></ShipmentAndReturnComponent>    
            </div>

            <FooterComponent></FooterComponent>
        </div>
    )
}

export default ShipmentAndReturn;