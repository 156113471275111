import React from 'react';
import { Link } from "react-router-dom";

/** Import CSS */
import '../../assets/styles/footer.scss';

const FooterComponent = () => {
    return(
        <div className="footer-main-section">
            <div className="wcm-container footer-alignment">
                <div className="footer-section">
                    <h4>About</h4>
                    <Link to={'/about'}>About Us</Link>
                    <Link to={'/service'}>Services</Link>
                    <Link to={'/howitworks'}>How it Works?</Link>
                </div>
                <div className="footer-section">
                    <h4>Support</h4>
                    <Link to={'/contact'}>Contact Us</Link>
                </div>
                <div className="footer-section">
                    <h4>Useful Links</h4>
                    <Link to={'/shipmentandreturns'}>Shipment and Return</Link>
                </div>
                <div className="footer-section">
                    <div className="footer-section-four">
                        <h4>Get in touch</h4>
                        <div>
                            <span>Email: </span>
                            <a href="mailto:support@wecraftmemories.com" rel="noreferrer">support@wecraftmemories.com</a>
                        </div>
                        <div>
                            <span>Phone: </span>
                            <a href="tel:8097987769" rel="noreferrer">+91 8097987769</a>
                        </div>
                    </div>
                    <div className="footer-section-four">
                        <h4>Follow us</h4>
                        <div className="footer-follow-us">
                            <a href="https://www.facebook.com/profile.php?id=61554979132861" className='facebook-icon' target="_blank" rel="noopener noreferrer">
                                <i className='bx bxl-facebook-circle'></i>
                            </a>
                            <a href="https://www.instagram.com/wecraftmemories01" className='instagram-icon' target="_blank" rel="noopener noreferrer">
                                <i className='bx bxl-instagram' ></i>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <div className="wcm-container footer-rights-reserved">Copyright © 2024 WeCraftMemories All rights reserved</div>
        </div>
    );
}

export default FooterComponent;