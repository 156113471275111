import React, { useState, useEffect } from 'react';

/** Import CSS */
import '../assets/styles/global.scss';
import '../assets/styles/productlist.scss';

/** Import Common Component */
import MenuComponent from '../components/common/MenuComponent';
import FooterComponent from '../components/common/FooterComponent';

/** Import Component */
import ProductFilterComponent from '../components/productlistsale/ProductFilterComponent';
import ProductListComponent from '../components/productlistsale/ProductListComponent';


function ProductList() {
    const initialFilters = {
        categories: [],
        ageGroups: [],
        genders: [],
        price: { min: 0, max: 500 },
        search: ""
    };

    const [filters, setFilters] = useState(initialFilters);
    const [openPopup, setOpenPopup] = useState(false);

    /** Calculate applied filter count */
    const filterCount = [
        ...filters.categories, 
        ...filters.ageGroups, 
        ...filters.genders, 
        ...(filters.search ? [filters.search] : [])
    ].length;

    /** Function to clear all filters */
    const clearAllFilters = () => {
        setFilters(initialFilters);
    };

    /** Redirect to top on page load */
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div className='main-body-section'>
            <MenuComponent />

            <div className='wcm-container first-section-innerpage'>
                <div className='productlist-container-main'>
                    <div className='productlist-filter-main'>
                        <div className='productlist-desktop-filter'>
                            <ProductFilterComponent filters={filters} setFilters={setFilters} />
                        </div>
                        <div className='productlist-mobile-filter'>
                            <div className='productlist-mobile-filter-div' onClick={() => setOpenPopup(!openPopup)}>
                                <i className='bx bx-filter-alt' ></i> <span>Filter</span> 
                                {filterCount > 0 && <span className='filter-count'>({filterCount})</span>}
                            </div>

                            {filterCount > 0 && (
                                <div className='productlist-mobile-clear-filter'>
                                    <button className='productlist-clear-filters-outside' onClick={clearAllFilters}>
                                        <i className='bx bx-trash'></i>
                                        <span>Clear All Filters</span>
                                    </button>
                                </div>
                            )}

                            {openPopup && (
                                <div className='productlist-mobile-filter-popup'>
                                    <div className='filter-popup-header'>
                                        <span className='filter-heading'>Filters</span>
                                        <i 
                                            className='bx bx-x filter-close-icon' 
                                            onClick={() => setOpenPopup(false)}
                                        ></i>
                                    </div>
                                    <ProductFilterComponent filters={filters} setFilters={setFilters} />
                                    <div className='productlist-mobile-apply-filter'>
                                        <button onClick={() => setOpenPopup(false)}>Apply Filter</button>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                    
                    <div className='productlist-products-main'>
                        <ProductListComponent filters={filters} />
                    </div>
                </div>
            </div>

            <FooterComponent />
        </div>
    )
}

export default ProductList;