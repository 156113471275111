const categories = [
    {
        "_id": "66ca2de227c5e6cc833a383e",
        "name": "Superhero",
        "image": "/uploads/categories/category_superhero.png",
        "description": "",
        "sortNumber": 1,
        "inUse": 1
    },
    {
        "_id": "66ca2de75c9734967db3c990",
        "name": "Cartoon",
        "image": "/uploads/categories/category_cartoons.png",
        "description": "",
        "sortNumber": 2,
        "inUse": 1
    },
    {
        "_id": "66ca2dea35366c9da215b63c",
        "name": "Anime",
        "image": "/uploads/categories/category_anime.png",
        "description": "",
        "sortNumber": 3,
        "inUse": 1
    },
    {
        "_id": "66ca2def7db050add39c152f",
        "name": "Uniform",
        "image": "/uploads/categories/category_uniforms.png",
        "description": "",
        "sortNumber": 4,
        "inUse": 1
    },
    {
        "_id": "66ca2df8054fb51550a6538a",
        "name": "Festival",
        "image": "/uploads/categories/category_festivals.png",
        "description": "",
        "sortNumber": 5,
        "inUse": 1
    },
    {
        "_id": "66ca2e007a482f0b188f50fd",
        "name": "Animal",
        "image": "/uploads/categories/category_animals.png",
        "description": "",
        "sortNumber": 6,
        "inUse": 1
    },
    {
        "_id": "66ca2e0468050826edef8d14",
        "name": "Flower",
        "image": "/uploads/categories/category_flowers.png",
        "description": "",
        "sortNumber": 7,
        "inUse": 1
    },
    {
        "_id": "66ca2e09c9c809651738a2f4",
        "name": "Food",
        "image": "/uploads/categories/category_food.png",
        "description": "",
        "sortNumber": 8,
        "inUse": 1
    },
    {
        "_id": "66ca2e0d8f66c19d5ae90a33",
        "name": "Movie",
        "image": "/uploads/categories/category_moviecharacters.png",
        "description": "",
        "sortNumber": 9,
        "inUse": 1
    },
    {
        "_id": "66ca2e120fb0ce09e7bf76c0",
        "name": "Games",
        "image": "/uploads/categories/category_games.png",
        "description": "",
        "sortNumber": 10,
        "inUse": 1
    }
];

module.exports = categories;