import React from 'react';
import Slider from "react-slick";

/** Import CSS */
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

/** Import Banner Images */
import bannerImage1 from '../../assets/images/banner/banner_1.jpg';
import bannerImage2 from '../../assets/images/banner/banner_2.jpg';
import bannerImage3 from '../../assets/images/banner/banner_3.jpg';
import bannerImage4 from '../../assets/images/banner/banner_4.jpg';

const BannerComponent = (props) => {
    const bannerImages = [
        bannerImage1,
        bannerImage2,
        bannerImage3,
        bannerImage4
    ];

    /** Slder settings */
    const settings = {
        dots: false,
        infinite: true,
        slidesToShow: 1,
        swipeToSlide: true,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 5000,
        pauseOnHover: true,
        nextArrow: null,
        prevArrow: null,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                }
            }
        ]
    };

    // function NextArrow(props) {
    //     const { onClick } = props;
    //     return (
    //         <div className="slick-arrow slick-next" onClick={onClick}>
    //             Next
    //         </div>
    //     );
    // }
    
    // function PrevArrow(props) {
    //     const { onClick } = props;
    //     return (
    //         <div className="slick-arrow slick-prev" onClick={onClick}>
    //             Prev
    //         </div>
    //     );
    // }

    return(
        <div className='banners'>
            <Slider {...settings}>
                {bannerImages.map((bannerImage, index) => (
                    <div className='banner' key={index}>
                        <img src={bannerImage} alt={`${index}`} className='banner-image' />
                    </div>
                ))}
            </Slider>
        </div>
    );
}

export default BannerComponent;