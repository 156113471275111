import React from "react";
import AppRouter from "./routes/AppRouter";

function App() {
  return (
      <div className="app-main-div">
          <AppRouter />
      </div>
  );
}

export default App;