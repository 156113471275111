import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom"; // Import for navigation
import productsService from "../../services/productsRent";
import categoryService from "../../services/categories";

const CategoryComponent = () => {
    const [filteredCategories, setFilteredCategories] = useState([]);
    const navigate = useNavigate(); // Initialize navigate

    useEffect(() => {
        // Fetch categories and products
        const fetchData = async () => {
            const allCategories = await categoryService;
            const allProducts = await productsService;

            // Filter categories that have matching products
            const categoriesWithProducts = allCategories.filter(category =>
                allProducts.some(product => product.categoryId === category._id)
            );

            // Sort categories by sortNumber (ascending order) and take the top 6
            const sortedTopCategories = categoriesWithProducts
                .sort((a, b) => a.sortNumber - b.sortNumber)
                .slice(0, 6);

            setFilteredCategories(sortedTopCategories);
        };
        fetchData();
    }, []);

    // Function to handle category click and navigate to the product page
    const handleCategoryClick = (categoryId) => {
        navigate("/rent/products", { state: { selectedCategory: categoryId } }); // Pass selected categoryId via state
    };

    return (
        <div className="list-container home-top-categories">
            <h1 className="home-page-heading">Top Categories</h1>

            {filteredCategories.length > 0 ? (
                <div className="category-list">
                    {filteredCategories.map((category) => (
                        <div
                            key={category._id}
                            className="category-card"
                            onClick={() => handleCategoryClick(category._id)}
                        >
                            <div className="category-image">
                                <img src={category.image} alt={category.name} />
                            </div>
                        </div>
                    ))}
                </div>
            ) : (
                <div className="no-products-found">
                    <p>No categories found.</p>
                </div>
            )}
        </div>
    );
};

export default CategoryComponent;