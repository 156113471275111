import React, { useEffect } from 'react';

/** Import CSS */
import '../assets/styles/howitworks.scss';

/** Import Common Component */
import MenuComponent from '../components/common/MenuComponent';
import FooterComponent from '../components/common/FooterComponent';

/** Import Component */
import HowItWorksComponent from '../components/howitworks/HowItWorksComponent';


function HowItWorks() {
    /** Redirect to top on page load */
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div className='main-body-section'>
            <MenuComponent></MenuComponent>

            <div className='first-section-innerpage'>
                <HowItWorksComponent></HowItWorksComponent>    
            </div>

            <FooterComponent></FooterComponent>
        </div>
    )
}

export default HowItWorks;