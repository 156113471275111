import React, { useState, useEffect, useCallback } from "react";
import { Link, useParams } from "react-router-dom";

/** Import Utils */
import EnquiryModalSale from "../../utils/EnquiryModalSale";

/** Import Services */
import productsService from "../../services/productsSale";
import ageGroupService from "../../services/ageGroups";

const ProductDetailComponent = () => {
    const { productId } = useParams();
    const [product, setProduct] = useState(null);
    const [ageGroup, setAgeGroup] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [selectedImage, setSelectedImage] = useState(null);
    const [showZoomedImage, setShowZoomedImage] = useState(false);
    const [zoomLevel, setZoomLevel] = useState(1);
    const [position, setPosition] = useState({ x: 0, y: 0 });
    const [isDragging, setIsDragging] = useState(false);
    const [lastMousePosition, setLastMousePosition] = useState({ x: 0, y: 0 });
    const [initialPinchDistance, setInitialPinchDistance] = useState(null);
    const [initialZoomLevel, setInitialZoomLevel] = useState(1);

    useEffect(() => {
        const productData = productsService.find((p) => p._id === productId);
        setProduct(productData);

        if (productData) {
            const ageGroupData = ageGroupService.find(
                (ag) => ag._id === productData.ageGroupId
            );
            setAgeGroup(ageGroupData);
            setSelectedImage(productData.displayImage || '/uploads/no_image_found.png');
        }
    }, [productId]);

    const handleImageClick = (img) => {
        setSelectedImage(img.image);
    };

    const handleEnquirySubmit = (formData) => {
        console.log("Enquiry submitted:", formData);
        setShowModal(false);

        /** Re-enable scrolling when the zoom modal is closed */
        document.body.classList.remove('scroll-lock');
    };

    const toggleZoomImage = () => {
        setShowZoomedImage(!showZoomedImage);
        setZoomLevel(1);
        setPosition({ x: 0, y: 0 });

        if (!showZoomedImage) {
            /** Disable scrolling when the zoom modal is open */
            document.body.classList.add('scroll-lock');
        } else {
            /** Re-enable scrolling when the zoom modal is closed */
            document.body.classList.remove('scroll-lock');
        }
    };

    const zoomIn = () => {
        setZoomLevel((prevZoom) => Math.min(prevZoom + 0.2, 3));
    };

    const zoomOut = () => {
        setZoomLevel((prevZoom) => Math.max(prevZoom - 0.2, 1));
    };

    const handleWheelZoom = (e) => {
        e.preventDefault();
        if (e.deltaY > 0) {
            zoomOut();
        } else {
            zoomIn();
        }
    };

    const handleMouseMove = useCallback((e) => {
        if (isDragging) {
            const dx = e.clientX - lastMousePosition.x;
            const dy = e.clientY - lastMousePosition.y;
            setPosition((prevPosition) => ({
                x: prevPosition.x + dx,
                y: prevPosition.y + dy,
            }));
            setLastMousePosition({ x: e.clientX, y: e.clientY });
        }
    }, [isDragging, lastMousePosition]);

    const handleTouchMove = useCallback((e) => {
        if (e.touches.length === 1 && isDragging) {
            const touch = e.touches[0];
            const dx = touch.clientX - lastMousePosition.x;
            const dy = touch.clientY - lastMousePosition.y;
            setPosition((prevPosition) => ({
                x: prevPosition.x + dx,
                y: prevPosition.y + dy,
            }));
            setLastMousePosition({ x: touch.clientX, y: touch.clientY });
        } else if (e.touches.length === 2) {
            // Handle pinch-to-zoom
            const newDistance = Math.sqrt(
                Math.pow(e.touches[0].clientX - e.touches[1].clientX, 2) +
                Math.pow(e.touches[0].clientY - e.touches[1].clientY, 2)
            );
            const scaleFactor = newDistance / initialPinchDistance;
            setZoomLevel(Math.min(Math.max(initialZoomLevel * scaleFactor, 1), 3));  // Zoom limits between 1 and 3
        }
    }, [isDragging, lastMousePosition, initialPinchDistance, initialZoomLevel]);

    const handleMouseDown = (e) => {
        setIsDragging(true);
        setLastMousePosition({ x: e.clientX, y: e.clientY });
    };

    const handleTouchStart = (e) => {
        if (e.touches.length === 1) {
            const touch = e.touches[0];
            setIsDragging(true);
            setLastMousePosition({ x: touch.clientX, y: touch.clientY });
        } else if (e.touches.length === 2) {
            // Two-finger touch for pinch zoom
            const distance = Math.sqrt(
                Math.pow(e.touches[0].clientX - e.touches[1].clientX, 2) +
                Math.pow(e.touches[0].clientY - e.touches[1].clientY, 2)
            );
            setInitialPinchDistance(distance);
            setInitialZoomLevel(zoomLevel);  // Store the current zoom level
        }
    };

    const handleMouseUp = () => {
        setIsDragging(false);
    };

    useEffect(() => {
        if (isDragging) {
            window.addEventListener('mousemove', handleMouseMove);
            window.addEventListener('touchmove', handleTouchMove);
            window.addEventListener('mouseup', handleMouseUp);
            window.addEventListener('touchend', handleMouseUp);
        } else {
            window.removeEventListener('mousemove', handleMouseMove);
            window.removeEventListener('touchmove', handleTouchMove);
            window.removeEventListener('mouseup', handleMouseUp);
            window.removeEventListener('touchend', handleMouseUp);
        }

        return () => {
            window.removeEventListener('mousemove', handleMouseMove);
            window.removeEventListener('touchmove', handleTouchMove);
            window.removeEventListener('mouseup', handleMouseUp);
            window.removeEventListener('touchend', handleMouseUp);
        };
    }, [isDragging, handleMouseMove, handleTouchMove]);

    const handleTouchEnd = (e) => {
        if (e.touches.length < 2) {
            setIsDragging(false);
            setInitialPinchDistance(null);  // Reset pinch distance after touch ends
        }
    };

    if (!product) {
        return <p>Product not found</p>;
    }

    const combinedImages = [
        { image: product.displayImage || '/path/to/fallback-image.jpg', imageTitle: product.productName },
        ...product.images.filter(img => img.image)
    ];

    const discountPercentage = Math.round(((product.prices.actualSalePrice - product.prices.discountedSalePrice) / product.prices.actualSalePrice) * 100)

    return (
        <div className="product-detail-container">
            <div className="page-breadcrumb">
                <span><Link to={'/'}>Home</Link></span>
                <span><i className='bx bx-chevron-right'></i></span>
                <span><Link to={'/sale/products'}>Product</Link></span>
                <span><i className='bx bx-chevron-right'></i></span>
                <span>Product Detail</span>
            </div>
            <div className="productdetail-row">
                <div className="productdetail-image-section">
                    <div className="productdetail-image-preview">
                        <ul>
                            {combinedImages && combinedImages.length > 0 && combinedImages.map((img, index) => (
                                <li key={index}>
                                    <img src={img.image} alt={img.title}
                                        className={selectedImage === img.image ? "selected" : ""}
                                        onClick={() => handleImageClick(img)} />
                                </li>
                            ))}
                        </ul>
                    </div>
                    <div className="productdetail-image-display">
                        <img src={selectedImage} alt="Selected product" onClick={toggleZoomImage} />

                        {discountPercentage > 0 &&
                            <div className="productdetail-product-discountpercent">   
                                {discountPercentage}% off
                            </div>
                        }
                    </div>
                </div>
                <div className="productdetail-detail-section">
                    <h1 className="productdetail-product-name">{product.productName}</h1>
                    <div className="productdetail-product-prices">
                        <div className="productdetail-product-row">
                            <div className="productdetail-product-rentdetails">
                                <label>Price: </label>
                                <div className="productdetail-product-offer-price">
                                    <div className="productdetail-product-rentprice">
                                        Rs. {product.prices.discountedSalePrice}/-
                                    </div>
                                    <div className="productdetail-product-actualprice">
                                        (Rs. {product.prices.actualSalePrice}/-)
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="productdetail-product-row">
                            <label>For Age: </label>
                            <div className="productdetail-product-depositprice">
                                {ageGroup && ageGroup.publicName ? (
                                    <p>{ageGroup.publicName}</p>
                                ) : (
                                    <p>Loading...</p>
                                )}
                            </div>
                        </div>

                        <div className="productdetail-product-row">
                            <label>Product Code: </label>
                            <div className="productdetail-product-depositprice">
                                {product && product.productCode ? (
                                    <p>{product.productCode}</p>
                                ) : (
                                    <p>Loading...</p>
                                )}
                            </div>
                        </div>

                        <div className="productdetail-send-enquiry">
                            <button
                                className="enquiry-button"
                                onClick={() => setShowModal(true, document.body.classList.add('scroll-lock'))}
                            >
                                <i className='bx bx-send' ></i> <span>Send Enquiry</span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="productdetail-row">
                <div className="productdetail-product-description">
                    <h3>About this item</h3>
                    <div className="product-description">{product.description}</div>
                </div>
            </div>
            <div className="productdetail-more-info">
                <h3>More info about this product:</h3>
                <div className="productdetail-table">
                    {product.attributes && product.attributes.length > 0 && product.attributes.map((attr, index) => (
                        <div className="productdetail-table-body" key={index}>
                            <div className="productdetail-body-heading">
                                {attr.attributeTitle} :
                            </div>
                            <div className="productdetail-body-value">
                                {attr.attributeValue}
                            </div>
                        </div>
                    ))}
                </div>
            </div>

            {showZoomedImage && (
                <div
                    className="zoom-modal"
                    onClick={toggleZoomImage}
                    onWheel={handleWheelZoom}
                    onTouchStart={handleTouchStart}
                    onTouchMove={handleTouchMove}
                    onTouchEnd={handleTouchEnd}
                >
                    <div className="zoom-modal-content" onClick={(e) => e.stopPropagation()}>
                        <div className="zoom-modal-controller">
                            <div className="zoom-controls">
                                <button onClick={zoomIn}><i className='bx bx-zoom-in'></i> <span className="zoom-controls-text">Zoom In</span></button>
                                <button onClick={zoomOut}><i className='bx bx-zoom-out' ></i> <span className="zoom-controls-text">Zoom Out</span></button>
                            </div>
                            <button className="zoom-modal-close-button" onClick={toggleZoomImage}><i className='bx bx-x'></i> <span className="zoom-controls-text">Close</span></button>
                        </div>

                        <div
                            className="zoomed-image-container"
                            style={{ overflow: "hidden", width: "80%", height: "500px" }}
                        >
                            <img
                                src={selectedImage}
                                alt="Zoomed product"
                                onMouseDown={handleMouseDown}  // Add the onMouseDown event here
                                style={{
                                    transform: `scale(${zoomLevel}) translate(${position.x}px, ${position.y}px)`,
                                    cursor: isDragging ? 'grabbing' : 'grab',
                                    transition: isDragging ? 'none' : 'transform 0.3s ease',
                                }}
                            />
                        </div>
                    </div>
                </div>
            )}

            <EnquiryModalSale
                show={showModal}
                onClose={() => setShowModal(false, document.body.classList.remove('scroll-lock'))}
                productDetails={{
                    productCode: product.productCode,
                    productImage: product.displayImage,
                    productName: product.productName,
                    price: product.prices.discountedSalePrice,
                    ageGroup: ageGroup ? ageGroup.publicName : "N/A",
                }}
                onSubmit={handleEnquirySubmit}
            />
        </div>
    );
};

export default ProductDetailComponent;