const genders = [
    {
        "_id": "66ca2d941d3c55163c6ec7bb",
        "name": "Boy",
        "image": "",
        "description": "",
        "sortNumber": 1,
        "inUse": 1
    },
    {
        "_id": "66ca2d9a304f32992dcd1660",
        "name": "Girl",
        "image": "",
        "description": "",
        "sortNumber": 2,
        "inUse": 1
    },
    {
        "_id": "66ca2d9ee3a6d6db04be3467",
        "name": "Unisex",
        "image": "",
        "description": "",
        "sortNumber": 3,
        "inUse": 1
    }
];

module.exports = genders;